const productsData = {
    categories: [
        {
          title: "Pumps",
          link: "/pumps",
          image: "/assets/img/pumps/pump.jpg",
          subCategories: [
            {
              title: "Positive Displacement Pumps",
              link: "/positive-displacement-pumps",
              img: "https://via.placeholder.com/600x765",
              items: [
                {
                  title: "SLR",
                  link: "/slr",
                  img: "https://via.placeholder.com/600x765",
                  hoverImg: "https://via.placeholder.com/600x765",
                  oldPrice: "",
                  price: "$400.00",
                  category: ["cloth",],
                  badge: [
                      {
                          title: "hot",
                          color: "#f36e4a",
                      }
                  ],
                  images: [
                      {
                        img: "https://via.placeholder.com/1400x933",
                      },
                      {
                        img: "https://via.placeholder.com/1400x933",
                      },
                      {
                        img: "https://via.placeholder.com/1400x933",
                      },
                      {
                        img: "https://via.placeholder.com/1400x933",
                      },
                  ]
                },
                {
                  title: "HLR",
                  link: "/hlr",
                  img: "https://via.placeholder.com/600x765",
                  hoverImg: "https://via.placeholder.com/600x765",
                  oldPrice: "",
                  price: "$400.00",
                  category: ["cloth",],
                  badge: [
                      {
                          title: "hot",
                          color: "#f36e4a",
                      }
                  ],
                  images: [
                      {
                        img: "https://via.placeholder.com/1400x933",
                      },
                      {
                        img: "https://via.placeholder.com/1400x933",
                      },
                      {
                        img: "https://via.placeholder.com/1400x933",
                      },
                      {
                        img: "https://via.placeholder.com/1400x933",
                      },
                  ]
                },
              ],
            },
            {
              title: "Centrifugal Pumps",
              link: "/centrifugal-pumps",
              img: "https://via.placeholder.com/600x765",
              items: [
                {
                  title: "DIN-FOOD",
                  link: "/din-food",
                  img: "https://via.placeholder.com/600x765",
                  hoverImg: "https://via.placeholder.com/600x765",
                  oldPrice: "",
                  price: "$400.00",
                  category: ["cloth",],
                  badge: [
                      {
                          title: "hot",
                          color: "#f36e4a",
                      }
                  ],
                  images: [
                      {
                        img: "https://via.placeholder.com/1400x933",
                      },
                      {
                        img: "https://via.placeholder.com/1400x933",
                      },
                      {
                        img: "https://via.placeholder.com/1400x933",
                      },
                      {
                        img: "https://via.placeholder.com/1400x933",
                      },
                  ]
                },
              ]
            },
            {
              title: "Side CHannel Pumps",
              link: "/side-channel-pumps",
              img: "https://via.placeholder.com/600x765",
              items: [
                {
                  title: "ASPIR",
                  link: "/aspir",
                  img: "https://via.placeholder.com/600x765",
                  hoverImg: "https://via.placeholder.com/600x765",
                  oldPrice: "",
                  price: "$400.00",
                  category: ["cloth",],
                  badge: [
                      {
                          title: "hot",
                          color: "#f36e4a",
                      }
                  ],
                  images: [
                      {
                        img: "https://via.placeholder.com/1400x933",
                      },
                      {
                        img: "https://via.placeholder.com/1400x933",
                      },
                      {
                        img: "https://via.placeholder.com/1400x933",
                      },
                      {
                        img: "https://via.placeholder.com/1400x933",
                      },
                  ]
                },
              ]
            }
          ],
        }, 
        {
          title: "Mixers",
          link: "/mixers",
          image: "/assets/img/pumps/pump.jpg",
          subCategories: [],
        }   
    ]
}

export {productsData};
