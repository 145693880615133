import React from 'react'

// Components
import OnlyCategories from '../../Components/Products/OnlyCategories';
import { fadeIn } from '../../Functions/GlobalAnimations';

// Libraries
import { Col, Container, Row } from 'react-bootstrap'
import { Parallax } from "react-scroll-parallax";
import { m } from 'framer-motion'
import Layout from "../layout"
// Data
import { productsData } from '../productsdata';

const Categories = (props) => {
  return (
   <Layout theme="light">
     <div style={props.style}>
      <m.div className="h-[380px] overflow-hidden sm:h-[365px] relative" {...fadeIn}>
                <Parallax className="lg-no-parallax bg-cover absolute top-0 left-0 w-full h-full" translateY={[-40, 40]} style={{ backgroundImage: `url(/assets/img/webp/portfolio-bg.webp)` }}></Parallax>
                <Container className="h-full relative">
          <Row className="justify-center h-[300px] sm:h-[250px] m-0 items-stretch w-full">
            <Col xl={6} lg={7} md={9} className="text-center flex justify-center flex-col font-serif xs:px-0">
              <h1 className="text-gradient bg-gradient-to-r from-[#556fff] via-[#e05fc4] to-[#ff798e] mb-[15px] leading-[20px] inline-block text-xmd">Shop by categories</h1>
              <h2 className="text-darkgray font-medium -tracking-[1px] mb-0">Shop categories</h2>
            </Col>
          </Row>
        </Container>
      </m.div>
      <section className="bg-[#fff] relative pt-0 py-[130px] px-[5%] xl:px-[1%] md:px-0 lg:py-[90px] md:py-[75px] sm:py-[50px] xs:px-0">
        <Container fluid>
          <Row>
            <Col className="sm:px-0">
              <OnlyCategories
               overlay="transparent"
               grid="grid grid-4col xl-grid-4col lg-grid-3col md-grid-2col sm-grid-2col xs-grid-1col gutter-extra-large"
               data={
                productsData.categories.map(i =>{
                    return {
                        img: i.image,
                        title: i.title,
                        link: `/products${i.link}`,
                        double_col: true,
                    }
                })
               }
            />
            </Col>
          </Row>
        </Container>
      </section>
    </div>
   </Layout>
  )
}

export default Categories;
