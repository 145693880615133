import React, { useState, useEffect, useRef, memo } from 'react'
import { Link } from 'react-router-dom';

// Libraries
import { m } from "framer-motion";
import { useLocation } from "react-router-dom";

// Components
import useOnClickOutside from "../Functions/UseOnClickOutside";

// Data
import { LandingDemoData } from "../Pages/LandingData";

const SideButtons = memo((props) => {
    const [show, setShow] = useState(false);
    const [visible, setVisible] = useState(false)
    const ref = useRef(null);
    useOnClickOutside(ref, () => setShow(false));
    const location = useLocation()

    useEffect(() => {
        window.addEventListener('scroll', () => {
            const scrolled = document.documentElement.scrollTop;
            if (scrolled > 100) {
                setVisible(true)
            }
        });
    }, [])

    useEffect(() => {
        const close = (e) => {
            if (e.keyCode === 27) {
                setShow(false)
            }
        }

        if (show === true) {
            document.querySelector('body').classList.add("overflow-hidden");
        } else {
            document.querySelector('body').classList.remove("overflow-hidden");
        }

        window.addEventListener('keydown', close)
        return () => window.removeEventListener('keydown', close)
    }, [show])

    useEffect(() => {
        setVisible(false)
        if (props.animation === false) {
            setVisible(true)
        }
    },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [location])

    return (
        <m.div className={`${show ? "block h-[100vh] left-0 overflow-hidden fixed top-0 w-full z-[16]" : "fixed z-[16]"}`} initial={{ opacity: 0, visibility: "hidden" }} animate={visible ? { opacity: 1, visibility: "visible" } : { opacity: 0, visibility: "hidden" }} transition={{ duration: 0.5 }}>
            <section className={`all-demo-btn-section z-10 lg:hidden${show ? " show" : ""}`} ref={ref}>
                <div className="all-demo bottom-[160px] bg-darkgray text-white lg:hidden shadow-[0_0_15px_rgba(0,0,0,0.15)]">
                    <Link to="/contact-us">
                        <span className="flex items-center hover:text-white">
                            <i className={`feather-shopping-bag align-middle text-[21px] mr-[10px]${show === true ? " mx-auto" : ""}`}></i>
                            {show !== true && <span className="leading-[23px] uppercase text-[14px] inline-block">Contact us</span>}
                        </span>
                    </Link>
                </div>
            </section>
        </m.div>
    )
})

export default memo(SideButtons)