import React from "react";
import Layout from "../layout";
import { Col, Container, Row } from 'react-bootstrap'
import { Parallax } from "react-scroll-parallax";
import { m } from 'framer-motion'
import { fadeIn } from '../../Functions/GlobalAnimations';

const Downloads = (pros) => {
    return (
        <Layout theme="light">
            <m.div className="h-[380px] overflow-hidden sm:h-[365px] relative" {...fadeIn}>
                <Parallax className="lg-no-parallax bg-cover absolute top-0 left-0 w-full h-full" translateY={[-40, 40]} style={{ backgroundImage: `url(/assets/img/webp/portfolio-bg.webp)` }}></Parallax>
                <Container className="h-full relative">
                    <Row className="row items-stretch justify-center h-[300px] md:h-[375px] sm:h-[350px]">
                        <Col xl={6} lg={7} md={8} xs={12} className="text-center flex justify-center flex-col">
                            <h1 className="text-xmd leading-[20px] font-serif text-gradient bg-gradient-to-r from-[#556fff] via-[#e05fc4] to-[#ff798e] mb-[15px] inline-block">Shop fullwide</h1>
                            <h2 className="text-darkgray mb-[0px] font-serif font-medium -tracking-[1px] xs:mb-[0px]">Every new print and color of the season</h2>
                        </Col>
                    </Row>
                </Container>
            </m.div>
            {/* Parallax Section End */}

            {/* Section Start */}
            <section className="bg-[#fff] relative pt-0 py-[130px] px-[5%] lg:py-[90px] md:py-[75px] sm:py-[50px] xs:px-0">
                <Container fluid>
                    <Row>
                        <Col className="xs:px-0">
                            Hello downloads
                        </Col>
                    </Row>
                </Container>
            </section>
        </Layout>
    );
}

export default Downloads;
