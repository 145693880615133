import { productsData } from "./productsdata";

const getProductsByCategory = (category) => {
    const filtered = productsData.categories.find(product =>{
        return product.link.replace("/", "") === category.toLowerCase().replace(" ", "");
    });
    if(!filtered) return [];
    let subCategoryProducts = [];
    filtered.subCategories.forEach(item => {
        item.items.forEach(catItem => subCategoryProducts.push(catItem));
    })
    return subCategoryProducts;
}

const getProductsBySubCategory = ({category, subCategory}) => {
    const _category = productsData.categories.find(product =>{
        return product.link.replace("/", "") === category.toLowerCase().replace(" ", "");
    });
    const _subCategory = _category.subCategories.find(subCat => {
        return subCat.link.replace("/", "") === subCategory.toLowerCase().replace()
    });
    return _subCategory.items;
}

const getCategory = (category) => {
    const filtered = productsData.categories.find(product =>{
        return product.link.replace("/", "") === category.toLowerCase().replace(" ", "");
    });
    if(!filtered) return null;

    return filtered;
}

const getSubCategory = ({category, subCategory}) => {
    const _category = productsData.categories.find(product =>{
        return product.link.replace("/", "") === category.toLowerCase().replace(" ", "");
    });
    const _subCategory = _category.subCategories.find(subCat => {
        return subCat.link.replace("/", "") === subCategory.toLowerCase().replace()
    });
    return _subCategory;
}

const getProductById =({category, subCategory, productId}) => {
    const _category = productsData.categories.find(product =>{
        return product.link.replace("/", "") === category.toLowerCase().replace(" ", "");
    });
    if(!_category) return null;
    const _subCategory = _category.subCategories.find(p => {
        return p.link.replace("/", "") === subCategory.toLowerCase().replace(" ", "");
    });
    if(!_subCategory) return null;
    const product = _subCategory.items.find(t =>{
        return t.link.replace("/", "") === productId.toLowerCase().replace(" ", "");
    })
    return product;
}

export {
    getProductsByCategory,
    getProductById,
    getCategory,
    getProductsBySubCategory,
    getSubCategory
}
