import React from "react";

// Libraries
import { Col, Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";

// Component
import Header, { HeaderNav, Menu } from "../../Components/Header/Header";
import SocialIcons from "../../Components/SocialIcon/SocialIcons";
import FooterStyle02 from "../../Components/Footers/FooterStyle02";
import SideButtons from "../../Components/SideButtons";
import InViewPort from "../../Components/InViewPort";

// Data

const SocialIconsData = [
  {
    color: "#828282",
    link: "https://www.facebook.com/",
    icon: "fab fa-facebook-f",
  },
  {
    color: "#828282",
    link: "https://www.instagram.com/",
    icon: "fab fa-instagram",
  },
  {
    color: "#828282",
    link: "https://twitter.com/",
    icon: "fab fa-twitter",
  },
];

const Layout = (props) => {
  
  return (
    <div style={props.style}>
      <SideButtons />
      
      <Header topSpace={{ md: true }} type="reverse-scroll">
        <HeaderNav
          fluid="fluid"
          theme={props.theme ? props.theme : "dark"}
          expand="lg"
          className="py-[0px] px-[35px] md:px-[15px] md:py-[20px] sm:px-0"
        >
          <Col lg={2} sm={6} xs={"auto"} className="mr-auto ps-0">
            <Link aria-label="header logo" className="flex items-center" to="/">
              <Navbar.Brand className="inline-block p-0 m-0">
                <img
                  className="default-logo"
                  width="111"
                  height="36"
                  loading="lazy"
                  src="/assets/img/webp/logo-white.webp"
                  data-rjs="/assets/img/webp/logo-white@2x.webp"
                  alt="logo"
                />
                <img
                  className="alt-logo"
                  width="111"
                  height="36"
                  loading="lazy"
                  src="/assets/img/webp/logo-green-dark.webp"
                  data-rjs="/assets/img/webp/logo-green-dark@2x.webp"
                  alt="logo"
                />
                <img
                  className="mobile-logo"
                  width="111"
                  height="36"
                  loading="lazy"
                  src="/assets/img/webp/logo-black.webp"
                  data-rjs="/assets/img/webp/logo-black@2x.webp"
                  alt="logo"
                />
              </Navbar.Brand>
            </Link>
          </Col>
          <Navbar.Toggle className="order-last md:ml-[17px]">
            <span className="navbar-toggler-line"></span>
            <span className="navbar-toggler-line"></span>
            <span className="navbar-toggler-line"></span>
            <span className="navbar-toggler-line"></span>
          </Navbar.Toggle>
          <Navbar.Collapse className="justify-center col-auto col-lg-8">
            <Menu {...props} />
          </Navbar.Collapse>
          <Col
            lg={2}
            xs={"auto"}
            className="justify-end pe-0 flex items-center"
          >
            <SocialIcons
              theme="social-icon-style-01"
              size="xs"
              iconColor="light"
              data={SocialIconsData}
            />
          </Col>
        </HeaderNav>
      </Header>
      <InViewPort>
        {props.children}
        <FooterStyle02 theme="dark" className="bg-[#262b35] text-slateblue" />
      </InViewPort>
    </div>
  );
};

export default Layout;
